<template>
  <div>
    <h1>Hello world</h1>
  </div>
</template>

<script>
export default {
  name: "ProductsCategores",
};
</script>

<style lang="sass" scoped></style>
